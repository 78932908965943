/* @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'); */

.container {
   /* flex: 1; 
   overflow-y: auto; */
   /* width: 100%; */
   height: 100vh;
   width: 100vw;

   background-color: orange;
   padding: 0px;
   /* display: flex;
   flex-direction: column; */
   /* justify-content: space-between; */
   /* justify-content: space-between; */
   /* align-items: center; */
   /* align-items: flex-start; */
   /* align-items: center; */
   /* align-content: flex-end; */
}

.activePageContainer {
   flex: 10;
   margin-top: 40px; /* Same height as header */

   background-color: aqua;
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 100vh;
   border: 5px dashed red;
   flex-grow: 10;
   overflow: auto;
   align-items: center;
}

.pageHeader {
   /* width: 300px;
   align-self: stretch; */
   /* background-color: var(--myYellow); */
   font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
   /* font-style: ; */
   padding: 10px;
   /* color: yellow; */
   font-size: 20px;
   text-align: center;
}

.scoreBoardTop {
   background-color: var(--myYellow);
   font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;

   padding: 10px;
   color: yellow;
   font-size: 20px;
   text-align: center;
}

.mainContentContainer {
   /* flex: 1 1 auto; */
   /* height: 200px; */
   margin: 2rem 0;
   padding: 1rem;
   /* color: white; */
   /* font-size: 20px; */

   /* flex-grow: 1; */

   /* background-color: var(--myBrown); */
   outline: green 10px solid;
   /* width: 600px; */
   /* margin: 0 auto; */
}

.spellingGameInput {
   font-size: 1.5rem;
   @media screen and (max-width: 400px) {
      font-size: 0.9rem;
   }
}

.contentDiv {
   background-color: green;
   padding: 10px;
   color: white;
   font-size: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-grow: 1; /* Take up the remaining space */
   display: flex;
   justify-content: center; /* Center horizontally */
   align-items: center; /* Center vertically */
   background-color: lightblue; /* For visualization */
}

.i4 {
   /* width: 300px; */
}

.dropBox {
   cursor: pointer;
   font-size: 1rem;
   padding: 0.8rem 0.8rem;
   /* border: white 1px; */
   border-radius: 50px;
   color: white;

   border-radius: 1rem;

   font-weight: 400;

   /* */
   /* padding: 1.2rem 0.4rem 1.2rem 0.4rem; */
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 0.8rem;
   border: none;
   animation: none !important;
}

.longString {
   /* width: 30%;
   /* overflow: break-word;
   white-space: nowrap;
   text-overflow: ellipsis; */
   /* word-break: break-all;
   word-break: break-word; */
   /* overflow-wrap: break-word;
   overflow-wrap: anywhere; */

   width: 50px; /* Set the desired width */
   word-wrap: break-word; /* Break the text at word boundaries */
   overflow-wrap: break-word; /* Alternative to word-wrap */
   hyphens: auto; /* Enable hyphenation for better line breaks */
   white-space: normal; /* Prevent text from collapsing into a single line */
}

.add-form button {
   /* text-transform: uppercase; */
   /* background-color: #76c7ad; */
}

.warning {
   background-color: #e5771f;
   /* padding: 1.8rem 0 0.2rem 0; */
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 0.2rem;
   color: rgb(198, 0, 0);
   font-size: 1.8rem;
}

.add-form {
   background-color: #e5771f;
   padding: 1rem 0 0.6rem 0;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 0.8rem;
}

.formBar {
   width: 100vw;
   /* display: flex;
   justify-content: center;
   align-items: center; */
   background-color: var(--myOrange);
   padding: 0rem 0rem 0rem 0rem;
   color: var(--myBrown);
}

.transcriptBar {
   width: 100vw;
   display: flex;
   justify-content: center;
   align-items: center;

   /* padding: 2px 0px 5px 0px; */
   padding-bottom: 2px;
   background-color: var(--myOrange);
   color: var(--myBrown);

   font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
   font-weight: 800;
}

.form-container {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 10px; /* Optional: Adds space between the items */
   width: 100%;
}

.form-container input[type='text'] {
   flex-grow: 1; /* Makes the input take the remaining space */
   /* padding: 10px;
   font-size: 16px; */
}

.form-container button {
   /* padding: 10px 20px;
   font-size: 16px; */
}

.newTableContainer {
   display: flex;
   flex-direction: column;
   width: 1000px;
   align-items: center;
   align-content: center;
   justify-content: center;

   width: 100%;
   max-width: 100%;
   margin: 0 auto;
}

table {
   /* border-collapse: collapse; */
   /* width: 90%; */
   padding: 0;

   border-spacing: 0px;

   border-radius: 5px;
   /* margin: 0 auto; */
   text-align: centre;
   /* display: flex;
   justify-content: center; */
   background-color: var(--myBrown);
   color: var(--myWhite);
   font-size: 1.5rem;
   /* border: 0px solid gold; */

   @media (max-width: 2000px) {
      width: 1000px;
   }
   @media (min-width: 800px) {
      width: 800px;
   }

   @media (max-width: 768px) {
      /* font-size: 1.4rem; */
      padding: 0;
      margin: 1px;
   }
}

.letterCount {
   /* font-size: '0.8rem'; */
}

.scrambledFont {
   font-family: 'Poppins', 'Helvetica Neue', Arial, sans-serif;
   letter-spacing: 5rem;
   font-weight: 200;
}

.scrambleIcon {
   /* font-size: 1.4rem; */
   margin-left: 5px;
   cursor: crosshair;
   @media (max-width: 768px) {
      /* font-size: 0.8rem;
      padding: 0;
      margin-left: 1px; */
   }
}

.unScrambledFont {
}

th,
td {
   padding: 1px;
   text-align: center !important;
   /* border: 1px solid orange; */
   justify-content: center;
   vertical-align: middle;
   vertical-align: center;
   @media (max-width: 768px) {
      vertical-align: middle;
      padding: 1rem 0 1rem 0;
      /* font-size: 1rem;
      padding: 0;
      margin: 0px;
      border: 0px; */
   }
}

th {
   font-family: 'Schoolbell', 'Helvetica Neue', Arial, sans-serif;
   background-color: black;
   padding: 1px;
   text-align: center !important;
   vertical-align: middle;
   font-size: 0.9rem;
   color: green;
}

/* .mainContainer {
   width: 2000px;
   height: 100vh;
   padding: 0 1rem 0 1rem;
   max-width: 100%;
   margin: 0 auto;
   border: 1px dashed lightgray;
} */

.doubleContainer {
   display: flex;
   flex-wrap: wrap;
   height: 100vh;
   width: 100%;
   justify-content: space-between;
   /* border: 1px dashed lightgray; */
   align-content: flex-start;
   padding: 10px;
}

.dropBo_x {
   cursor: pointer;
   font-size: 1rem;
   padding: 0.4rem 0.4rem;
   /* border: white 1px; */
   border-radius: 50px;
   color: white;
   border-radius: 0.5rem;
   font-weight: 400;
   border-style: dashed;
   /* */
   /* padding: 1.2rem 0.4rem 1.2rem 0.4rem; */
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 0.8rem;
   color: green;
   background-color: lightgray;
   border: 2px dotted green;
}

.tableTitleBar {
   display: flex;
   flex-wrap: wrap;
   /* justify-content: space-between; */
   width: 100%;
   flex-basis: 100%;
   flex-grow: 1;
}
.tableTitleBar thead {
   flex-basis: 100%;
   flex-grow: 1;
}

.responsive-table {
   width: 96%;
   margin: 0;
   /* max-width: 100%; */
   min-width: 300px; /* Adjust as necessary */
   max-width: 1000px; /* Adjust as necessary */
   @media (min-width: 900px) and (max-width: 1000px) {
      max-width: 840px;
   }
   @media (min-width: 800px) and (max-width: 900px) {
      max-width: 780px;
   }
   @media (min-width: 700px) and (max-width: 800px) {
      max-width: 660px;
   }
   /* @media (min-width: 1280px) {
      max-width: 800px;
   } */
}

.responsive-table thead {
   flex-basis: 100%;
}

.responsive-table tbody {
   display: flex;
   flex-wrap: wrap;
   width: 100%;
}

.responsive-table tr {
   display: flex;
   flex-basis: 100%;
   flex-direction: row;
}

.responsive-table th,
.responsive-table td {
   /* flex-basis: 10%; */
   padding: 3px;
   border-left: none;
   border-right: none;
   border-top: none;
   /* border-bottom: dashed 1px var(--myYellow); */
   text-align: center;
   vertical-align: middle;
   justify-content: center;
   padding: 1rem 0 1rem 0;
   /* padding: 0.5rem; */
   /* border: 1px solid orange; */
   /* text-align: left; */
}

.c1 {
   flex-basis: 4%;

   color: #e5771f;
   flex-shrink: 2;
   text-align: center;
   align-items: center;
   align-content: center;
   background-color: black;
   padding: 0 0px 0 20px;
   /* margin-left: 2px; */
   font-size: 0.8rem;
   @media (max-width: 768px) {
      flex-basis: 4%;
   }
   @media screen and (max-width: 500px) {
      font-size: 0.7rem;
   }
}
.c2 {
   flex-basis: 12%;
   flex-shrink: 2;
   text-align: center;
   padding: 0;
   border-right: none;
   cursor: help;
   vertical-align: middle;
   align-items: center;
   justify-content: center;
   display: flex;
   align-items: center;
   @media screen and (max-width: 500px) {
      font-size: 0.9rem;
   }
}
.c3 {
   flex-basis: 6%;
   flex-shrink: 2;
   text-align: center;

   vertical-align: middle;
   padding: 0;
   border-left: dashed var(--myYellow);
   cursor: help;
   display: flex;
   align-items: center;
   @media screen and (max-width: 500px) {
      font-size: 0.9rem;
   }
}

.c4 {
   flex-basis: 15%;
   flex-shrink: 2;
   flex-grow: 1;
   text-align: center;

   letter-spacing: 0px;
   font-weight: 200;
   color: 'var(--myWhite)';
   font-family: 'Schoolbell', 'Helvetica Neue', Arial, sans-serif;
   font-weight: 400;
   font-style: normal;
   display: flex;
   align-items: center;

   @media screen and (max-width: 500px) {
      font-size: 0.9rem;
   }
}
.c5 {
   flex-basis: 25%;
   flex-shrink: 2;
   flex-grow: 1;
   text-align: center;
   display: flex;
   align-items: center;
   @media screen and (max-width: 500px) {
      font-size: 0.9rem;
   }
}
.c6 {
   font-family: 'Space Mono', 'Helvetica Neue', Arial, sans-serif;
   font-size: 1rem;
   color: var(--myYellow);
   flex-basis: 6%;
   flex-shrink: 2;
   text-align: center;
   display: flex;
   align-items: center;
   @media screen and (max-width: 500px) {
      font-size: 0.7rem;
   }
}
.c7 {
   flex-basis: 8%;
   flex-shrink: 2;
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
   @media screen and (max-width: 500px) {
      font-size: 0.9rem;
   }
}

.g1 {
   flex-basis: 4%;
   color: #e5771f;
   flex-shrink: 2;
   text-align: center;
   background-color: black;
   padding: 0 0px 0 10px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-family: 'Space Mono', monospace, 'Helvetica Neue', Arial,
      sans-serif;
   font-size: 0.8rem;
   /* margin-left: 2px; */
   @media (max-width: 768px) {
      flex-basis: 4%;
   }
}
.g2 {
   flex-basis: 8%;
   flex-shrink: 2;
   text-align: center;
   padding: 0;
   border-right: none;
   cursor: help;
   display: flex;
   align-items: center;
   justify-content: center;
}

.g23 {
   flex-basis: 8%;
   flex-shrink: 3;
   text-align: center;
   padding: 0;
   border-right: none;
   cursor: help;
   display: flex;
   align-items: center;
   justify-content: center;
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-basis: 10%;
   flex-shrink: 2;
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: space-evenly;
   line-height: 1.8rem;
}
.g3 {
   flex-basis: 6%;
   flex-shrink: 2;
   text-align: center;

   vertical-align: middle;
   padding: 0;
   border-left: dashed var(--myYellow);
   cursor: help;
   display: flex;
   align-items: center;
   justify-content: center;
}

.g4 {
   flex-basis: 32%;
   flex-shrink: 2;
   flex-grow: 1;
   text-align: center;
   flex-wrap: wrap; /* Ensure contents use flex wrap */

   letter-spacing: 2px;

   color: 'var(--myWhite)';

   font-family: 'Quicksand', 'Helvetica Neue', Arial, sans-serif;
   font-optical-sizing: auto;

   font-weight: 600;
   font-style: normal;
   display: flex;
   align-items: center;
   justify-content: center;
   @media (max-width: 768px) {
      font-size: 0.9rem;
      vertical-align: middle;
      padding-top: 20px;
   }
}

.g4h {
   flex-basis: 32%;
   flex-shrink: 1;
   flex-grow: 1;
   text-align: center;

   letter-spacing: 2px;
   font-weight: 200;

   display: flex;
   align-items: center;
   justify-content: center;
   @media (max-width: 768px) {
      font-size: 1rem;
      vertical-align: middle;
      padding-top: 20px;
   }
}

.g5 {
   flex-basis: 38%;
   flex-shrink: 1;
   flex-grow: 1;
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
}
.g6 {
   font-size: 1.6rem;
   flex-basis: 8%;
   flex-shrink: 2;
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: space-evenly;
   line-height: 1.6rem;
   @media screen and (max-width: 600px) {
      line-height: 1.1rem;
      font-size: 1.2rem;
   }
   @media screen and (max-width: 400px) {
      line-height: 0.9rem;
      font-size: 0.9rem;
   }
}
.g7 {
   flex-basis: 10%;
   flex-shrink: 2;
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
   padding-right: 1rem;
}

/* Responsive styles */
@media (max-width: 768px) {
   .responsive-table tr {
      /* flex-direction: column; */
      border-top: none;
      border-bottom: none;
      border-left: none;
      border-right: none;
   }

   /* .responsive-table th, */
   .responsive-table td {
      /* flex-basis: 100%; */
      display: flex;
      border-top: dashed 1px var(--myYellow);
      border-left: none;
      border-right: none;
      border-bottom: none;
      /* padding: 1rem 0 1rem 0; */
   }

   .responsive-table td::before {
      /* content: attr(data-label); */
      /* font-weight: bold;
      margin-right: 10px;
      flex-basis: 50%; */
   }
}
