.multiQuestion {
   width: 100%;
   box-sizing: border-box;
   height: 3rem;
}

/* Basic styling for the select element */
select {
   appearance: none; /* Remove default styling in modern browsers */
   -webkit-appearance: none; /* Remove default styling in Safari */
   -moz-appearance: none; /* Remove default styling in Firefox */
   width: 160px;
   padding: 10px;
   font-size: 16px;
   border: 2px solid #4caf50;
   border-radius: 4px;
   background-color: #fff;
   color: #333;
   background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%234CAF50"><path d="M7 10l5 5 5-5z"/></svg>'); /* Custom dropdown arrow */
   background-repeat: no-repeat;
   background-position: right 10px center;
   background-size: 12px 12px;
   border-radius: 20px;
   text-align: center;
   margin-left: 0.1rem;
   margin-right: 0.3rem;
   font-family: 'Schoolbell', 'Helvetica Neue', Arial, sans-serif;
   color: green;
   @media (max-width: 500px) {
      width: 80px;
      height: 2.5rem;
      font-size: 0.9rem;
   }
}

/* Basic styling for the options */
select option {
   background-color: yellow;
   color: brown;
   text-align: center;
}

input[type='radio'] {
   cursor: pointer;
}

.multiQuestionDiv {
   margin: 10px;

   padding: 10px;
   border: 8px groove var(--myBrown);
   border-radius: 20px;
   text-align: center;
   display: flex;
   flex-direction: row;
   background-color: var(--myWhite);
   align-items: center;
   font-family: 'Lato', 'Helvetica Neue', Arial, sans-serif;
   font-weight: 600;
   @media (max-width: 500px) {
      font-weight: 400;
      font-size: 0.9rem;
   }
}

.correctOption {
   color: green;
   background-color: var(--myWhite);
   border: lightgreen 4px solid;
   border-radius: 15px;
   padding: 0.2rem 0.5rem 0.2rem 0.5rem;
   margin: 0.2rem;
   font-weight: 600;
}
