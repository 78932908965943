@import url('https://fonts.googleapis.com/css2?family=Monoton&family=Quicksand:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Delicious+Handrawn&family=Gloria+Hallelujah&family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Agbalumo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Judson:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Schoolbell&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ojuju:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Madimi+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=ZCOOL+KuaiLe&display=swap');

:root {
   --myBrown: #5a3e2b;
   --myYellow: #f4a226;
   --myOrange: #e5771f;
   --myWhite: #ffebb3;
   --africanFont: 'Madimi One', sans-serif;

   --robotFont: 'Roboto', sans-serif;
   --computerFont: 'Orbitron', sans-serif;
   font-synthesis: none;
   text-rendering: optimizeLegibility;
   -webkit-font-smoothing: antialiased;
}

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

html {
   height: -webkit-fill-available;

   overflow-y: hidden;
   overflow-x: hidden;
   /* overscroll-behavior: none; */
   /* overflow-y: hidden; */
   box-sizing: border-box;
}

body {
   /* max-width: 1400px; */
   /* display: flex;
   flex-direction: column; */
   /* height: 100vh; */
   /* text-align: center; */
   /* height: 100vh; */
   overflow-y: auto;
   overflow-x: hidden;
   width: 100vw;
   height: 100vh;
   height: -webkit-fill-available;
   margin: 0;

   background-color: var(--myOrange);
   font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;

   /* box-sizing: border-box; */
   /* border: brown 5px dashed; */
}

.allContainer {
   /* height: -webkit-fill-available; */
   /* box-sizing: border-box; */
   margin: 0 auto;
   /* margin-top: 40px;
   margin-bottom: 20px; */
   width: 100%;
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   /* z-index: 0; */
   @media screen and (max-width: 600px) {
      /* margin-top: 30px !important; */
   }
}

.chineseFont {
   font-family: 'ZCOOL KuaiLe', sans-serif;
   font-weight: 400;
   font-style: normal;
}

.mainContainer {
   background-color: var(--myYellow);
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 96%;
   flex-grow: 10;
   overflow: auto;
   align-items: center;
   overflow-y: auto;
   overflow-x: hidden;
   padding-left: 0rem;
   padding-right: 0rem;
   margin-top: 40px;
   margin-bottom: 20px;
   @media screen and (max-width: 600px) {
      margin-top: 35px !important;
   }
}

.computerFont {
   font-family: 'Orbitron', 'Helvetica Neue', Arial, sans-serif;
}
.hero {
   width: 100%;
   z-index: -1;
}

.mainContainer::before {
   /* content: '';
   background-image: url('./media/background5.png');
   background-size: auto;
   background-position-x: center;
   background-position-y: center;
   background-repeat: space;
   position: absolute;
   background-size: contain;
   background-repeat: no-repeat;
   top: 0px;
   right: 0px;
   bottom: 0px;
   left: 0px;
   opacity: 0.08;
   z-index: -1; */
}

h1 {
   background-color: var(--myOrange);
   width: 100vw;
   /* padding: 5px 0 0px 0; */
   text-align: center;
   color: var(--myBrown);
   letter-spacing: 4px;
   padding-top: 0.2rem;

   font-family: 'Madimi One', 'Helvetica Neue', Arial, sans-serif;
   font-weight: 400;
   font-style: normal;
   @media (max-width: 600px) {
      font-size: 1.4rem;
      padding-top: 0.1rem;
      letter-spacing: 1px;
   }
}

h2 {
   padding: 5px;
   text-align: center;
   color: var(--myBrown);
   letter-spacing: 4px;
   font-optical-sizing: auto;
   font-weight: 600;
   font-family: 'Madimi One', 'Helvetica Neue', Arial, sans-serif;
   font-weight: 400;
   font-style: normal;
}

.africanFont {
   font-family: 'Madimi One', 'Helvetica Neue', Arial, sans-serif;

   font-weight: 400;
   font-style: normal;
}

a:link,
a:visited {
   color: inherit;
   text-decoration: none;
}

.largeIcon {
   cursor: pointer;
   font-size: 1.8rem;
   padding: 0rem 0.3rem;
   margin-bottom: -2rem;
   @media (max-width: 768px) {
      font-size: 1.4rem;
      /* padding: 0.2rem; */
   }
}

.mediumIcon {
   cursor: pointer;
   font-size: 0.9rem;
   padding: 0.3rem 0.3rem;
}

input {
   /* background-color: #ffebb3; */
   font-size: 1.6rem;
   color: #5a3e2b;
   font-family: 'Schoolbell', 'Helvetica Neue', Arial, sans-serif;
   border: none;
   border: #5a3e2b;
   border-style: solid 1px;
   border-radius: 0.3rem;
   /* padding: 1rem 1rem; */
   font-weight: 300;
   /* font-size: 1.8rem; */
   height: 2.2rem;
   cursor: text;
   border-style: none;
   color: rgb(37, 185, 37);
   text-align: center;
   animation: moveInLeft 200ms ease-out;
   @media (max-width: 768px) {
      font-size: 1.4rem;
      /* height: 1.5rem; */
   }
   @media (max-width: 400px) {
      font-size: clamp(0.9rem, 1.1rem, 1.4rem);
   }
}

input[type='text'] {
   /* width: 100px; */
   transition: ease-in-out, width 0.35s ease-in-out;
}

input[type='text']:focus {
   width: 96%;
}

input:focus {
   /* background-color: rgb(255, 255, 138); */

   outline: lime 2px !important;
   border: 2px solid lime;
   box-shadow: 0 0 5px #cea071;
}

input::placeholder {
   font-family: 'Indie Flower', 'Helvetica Neue', Arial,
      sans-serif;
   font-size: 1rem;
   font-weight: bold;
   opacity: 0.5;
   color: var(--myBrown);
}

input:disabled::placeholder {
   font-family: 'Schoolbell', 'Helvetica Neue', Arial, sans-serif;
   /* font-size: 1.2rem; */
   /* font-weight: bold; */
   opacity: 1;
   color: pink;
   text-decoration: none;
}

.cancelBtn {
   background-color: lightgray;
   font-size: 0.8rem;
   padding: 0.2rem;
   color: black;
}
.confirmBtn {
   background-color: green;
   font-size: 0.8rem;
   padding: 0.3rem;
   animation: flash-border 1s infinite;
}

button {
   background-color: green;
   padding: 0.2rem 0.7rem 0.3rem 0.7rem;
   margin: 0.1rem;
   border: 1px solid transparent;
   color: var(--myWhite);
   border-radius: 0.3rem;

   font-size: 1.2rem;
   font-weight: 400;
   height: 2rem;
   letter-spacing: -0.05rem;

   font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
   transition: border-color 0.25s;
   /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.19); */

   box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
   animation: moveInRight 600ms ease-out;

   @media (max-width: 800px) {
      font-size: 0.8rem;
      padding: 0 0.5rem 0 0.5rem;
   }
}

button:hover {
   transform: translateY(-0.0125rem);
   cursor: pointer;
   /* box-shadow: 0 0.1rem 0.1rem yellow; */
   color: yellow;
   /* background-color: darken(black, 30%); */
   filter: brightness(80%);
   /* border: yellow solid 1px; */
   border-color: var(--myWhite);
}

button:focus,
button:focus-visible {
   outline: 4px auto -webkit-focus-ring-color;
   outline: auto 2px Highlight;
   outline: auto 5px -webkit-focus-ring-color;
}

button:active {
   transform: translateY(-0.1rem);
   box-shadow: 0 0.1rem 0.3rem white;
}

.gameFont {
   font-family: 'VT323', 'Helvetica Neue', Arial, sans-serif,
      monospace;
   font-weight: 400;
   font-style: normal;
}

.loading-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(
      0,
      0,
      0,
      0.5
   ); /* Semi-transparent black background */
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 9999; /* Ensure it's on top of everything else */
}

.loading-content {
   background-color: white;
   padding: 20px;
   border-radius: 4px;
   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.left {
   padding: 0.2rem;
   float: left;
}
.right {
   padding: 0.2rem;
   float: right;
}
.center {
   text-align: center;
   padding: 0.2rem;
}

.red {
   color: red;
}
.green {
   color: rgb(0, 179, 0);
}

.correction {
   color: rgb(255, 92, 33);
   font-size: 0.9rem;
   font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
   font-weight: 300;
}

.myOrange {
   color: var(--myOrange);
}

.goButton {
   background-color: green;
   font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
   font-size: 1rem;
   padding: 2px 5px 2px 5px;
   /* border-radius: 5px; */
   margin: 0 0.2rem 0 0.2rem;
}

.wordTarget {
   padding: 1px;
   font-family: 'Quicksand', 'Helvetica Neue', Arial, sans-serif;
   font-size: 1.2rem;
   padding: 2px;
}
.wordTarget:hover {
   cursor: crosshair;
   outline: dashed 1px red;
   padding: 2px;
   color: var(--myOrange);
}

.countdown-timer2 {
   position: absolute;
   bottom: 65px;

   left: 20px;
   width: 100px;
   height: 100px;

   background-color: red;
   border: yellow 2px solid;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   opacity: 0.6;
   font-weight: bold;
   text-align: center;
   cursor: default;
   user-select: none;
   pointer-events: none;
   color: yellow;
   font-family: 'Orbitron', 'Helvetica Neue', Arial, sans-serif;
   z-index: 0;
}

.countdown-timer2.flashing {
   animation: flash 0.01s ease-in-out;
   background-color: #d58433;
   border: red;
}

@keyframes flash {
   0% {
      opacity: 0.6;
   }
   50% {
      opacity: 0.3;
   }
   100% {
      opacity: 0.6;
   }
}

.unselectable {
   user-select: none; /* Standard syntax */
   -webkit-user-select: none; /* For Safari */
   -moz-user-select: none; /* For Firefox */
   -ms-user-select: none; /* For Internet Explorer/Edge */
}

@keyframes moveInRight {
   0% {
      opacity: 0.1;
      transform: translateX(+30rem);
   }
   /* 20% {
      transform: rotate(90deg);
   }
   50% {
      transform: rotate(180deg);
   } */
   50% {
      transform: translateX(-2rem);
   }

   100% {
      opacity: 1;
      transform: translate(0);
   }
}

@keyframes moveInLeft {
   0% {
      opacity: 0.3;
      transform: translateX(-30rem);
   }
   /* 20% {
      transform: rotate(90deg);
   }
   50% {
      transform: rotate(180deg);
   } */
   50% {
      transform: translateX(-2rem);
   }

   100% {
      opacity: 1;
      transform: translate(0);
   }
}

.spelling-table-container {
   padding-left: 1rem;
   padding-right: 1rem;

   @media (max-width: 480px) {
      width: 100vw;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
   }

   @media (min-width: 481px) and (max-width: 667px) {
      width: calc(100vw - 1rem);
   }
   @media (min-width: 300px) and (max-width: 480px) {
      width: calc(100vw - 0.5rem);
   }

   @media (min-width: 768px) {
      width: 700px;
   }
   @media (min-width: 968px) {
      width: 800px;
   }

   @media (min-width: 1024px) {
      width: 1000px;
   }
}

.pokemons2 {
   text-align: center !important;
   align-items: center !important;
   position: relative;

   /* animation: mymove 0.6s; */
   animation-delay: 0s;
   animation-fill-mode: forwards;
   opacity: 0.6;
   animation: myMove 800ms;
}

@keyframes enter-shake-rotate {
   0% {
      top: 3000px;
      transform: rotate(0deg) scale(1);
   }
   20% {
      transform: rotate(0deg) scale(1);
   }
   25% {
      transform: rotate(15deg) scale(2);
   }
   30% {
      transform: rotate(30deg) scale(2);
   }
   35% {
      transform: rotate(60deg) scale(3);
   }
   40% {
      transform: rotate(90deg) scale(4);
   }
   45% {
      transform: rotate(120deg) scale(5);
   }
   55% {
      transform: rotate(180deg) scale(4);
   }
   65% {
      transform: rotate(270deg) scale(3);
   }
   75% {
      transform: rotate(360deg) scale(2);
   }
   95% {
      transform: rotate(180deg) scale(1);
   }
   100% {
      top: 0px;
      transform: rotate(0deg) scale(1);
   }
}

@keyframes myMove {
   0% {
      top: 2000px;
      transform: scale(26) rotate(359deg);
   }
   /* 50% {
      top: 300px;
      transform: scale(4) rotate(359deg);
   }
   75% {
      top: 150px;
      transform: scale(2) rotate(180deg);
   } */
   100% {
      top: 0px;
      transform: scale(1);
   }
}

.giraffe {
   animation: rotateGiraffe 1s linear infinite;
   display: inline-block;
}

@keyframes rotateGiraffe {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(180deg);
   }
}

.jumpingGiraffe {
   animation: jumpGiraffe 1s ease-in-out infinite;
   display: inline-block;
}

@keyframes jumpGiraffe {
   0% {
      transform: translateY(0);
   }
   50% {
      transform: translateY(-10px);
   }
   100% {
      transform: translateY(0);
   }
}
