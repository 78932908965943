.navBarBackground {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 40px;
   background-color: var(--myBrown);
   z-index: 10;
   @media screen and (max-width: 600px) {
      height: 35px !important;
   }
}

.navBar {
   position: fixed;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   text-align: center;
   color: var(--myYellow);
   /* text-decoration: none; */

   background-color: var(--myBrown);
   max-width: 1000px;
   width: 100%;
   /* padding: 12px; */
   opacity: 0.9;
   height: 40px;
   z-index: 100;
   line-height: 80% !important;
   /* height: 3vh; */
   @media (max-width: 600px) {
      height: 35px;
   }
}

.footer {
   /* border-top: dashed gray 5px; */
   opacity: 0.9;
   position: fixed;
   bottom: 0;
   left: 0;
   width: 100%;
   text-align: center;
   background-color: var(--myBrown);
   margin: 0 auto;
   /* margin-top: 30px; */
   color: white;
   /* height: 1.5vh; */
   height: 20px;
   vertical-align: middle;
   @media screen and (max-width: 600px) {
      height: 15px;
   }
}

.footer .content {
   color: #ffebb3;
   text-decoration: none;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   color: var(--myWhite);
   font-size: 1rem;
   text-align: center;

   font-size: 1rem;
   font-weight: 400;
   word-spacing: 4px;
   letter-spacing: 2px;
   width: 100%;
   @media screen and (max-width: 600px) {
      font-size: 0.5rem;
   }
}

.activeLink {
   color: var(--myWhite);
   text-decoration: wavy underline var(--myWhite);

   font-family: 'Madimi One', 'Helvetica Neue', Arial, sans-serif;
   font-weight: 400;
   font-style: normal;
   margin-left: 0.3rem;
   margin-right: 0.3rem;
   animation: glowingGold 1s infinite;
   color: var(--myWhite);
   text-decoration: underline var(--myWhite) !important;
   font-size: 1.5rem;
   text-align: center;
   line-height: 75%;
   @media (max-width: 600px) {
      font-size: 0.9rem !important;
      animation: glowingGold 1s infinite;
   }
   animation: glowingGold 1s infinite;
}

.inactiveLink {
   font-size: 1.5rem;
   text-align: center;

   text-decoration: none;

   font-family: 'Madimi One', 'Helvetica Neue', Arial, sans-serif;
   font-weight: 400;
   font-style: normal;
   margin-left: 0.3rem;
   margin-right: 0.3rem;
   color: var(--myYellow);
   line-height: 80% !important;
   @media (max-width: 600px) {
      font-size: 0.9rem;
   }
}

.inactiveLink:hover {
   color: #b8860b;
   text-shadow: 0 0 1px #d9d93d, 0 0 1px #f2ff00;
   transition: color 0.7s, text-shadow 4.4s;
}

.animate-color {
   font-size: 2rem;
   font-weight: bold;
   animation: colorChange 3s infinite;
}

@keyframes colorChange {
   0% {
      color: rgb(145, 255, 0);
   }
   25% {
      color: orange;
   }
   50% {
      color: yellow;
   }
   75% {
      color: rgb(196, 196, 5);
   }
   100% {
      color: rgb(255, 187, 0);
   }
}

@keyframes glow {
   from {
      text-shadow: 0 0 20px #ecffbd;
   }
   to {
      text-shadow: 0 0 50px #d9d93d, 0 0 30px #f2ff00;
   }
}

@keyframes glowingGold {
   0% {
      color: var(--myWhite);
      text-decoration: underline var(--myWhite);
   }
   25% {
      color: var(--myWhite);
      text-decoration: underline var(--myWhite);
   }

   50% {
      color: var(--myWhite);
      text-decoration: way underline var(--myWhite);
      /* text-shadow: 0 0 1px #d9d93d; */
   }
   /* 75% {
      color: #ffbf00;
      text-shadow: 0 0 1px #ffda99;
   }
   100% {
      color: #ffec83;
      text-shadow: 0 0 1px #ffefc0;
   } */
}
