label {
   margin: 0.5rem;
   font-family: var(--africanFont), 'Helvetica Neue', Arial,
      sans-serif;
   font-size: 1.1rem;
}

.wordList {
   border: 5px ridge var(--myBrown);
   margin: 1rem;
   padding: 1.5rem;
   font-size: 1.4rem;
   border-radius: 2rem;
   line-height: 1.4;
   position: relative;
   background-color: var(--myBrown);
   color: white;
   opacity: 0.7;
}

.yearButtons {
   display: flex;
   flex: 1 1 100%;
   flex-wrap: wrap;
   justify-content: center;
   width: 100%;
   margin: 0 0px 1rem 0px auto;
   padding: 0 10px 0 10px;
   justify-content: flex-start;
   align-content: flex-start;
   align-items: stretch;
   border: 0px solid black;
}

.menuButton {
   font: 'Quicksand', sans-serif;
   letter-spacing: 1px;
   font-size: 0.8rem;
   padding: 1px 4px 1px 4px;
   /* margin: 0.5rem; */
   border-radius: 0.5rem;
   /* border: 1px ridge var(--myBrown); */
   @media screen and (min-width: 768px) {
      font-size: 1.3rem;
      padding: 5px 10px 5px 10px;
   }
}

.buttonsContainer {
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   align-content: center;
}

.backButtonStyle {
   background-color: white;
   color: black;
   border: 1px solid black;
}

.leftFormBox {
   display: flex;
   flex: 1 1 auto;
   align-items: center;
   text-align: center;
   align-self: flex-start;
   flex-grow: 0;
   flex-shrink: 1;
   width: auto;
   /* flex-basis: 0; */
   padding: 0.1rem;
   justify-content: center;
   align-items: center;
   align-content: center;
}

.rightWordListBox {
   margin-top: 1rem;
   flex-grow: 2;
   flex-shrink: 2;
   flex-basis: 0;
   margin-bottom: 50px;
}

fieldset {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   border: 5px ridge var(--myBrown);
   padding: 1rem;
   margin: 0 0.4rem 0 0.4rem;
   border-radius: 1rem;
   /* background-color: var(--myBrown); */
   color: var(--myBrown);
   font-family: 'Lato', 'Helvetica Neue', Arial, sans-serif;
   font-family: 'Madimi One', 'Lato', 'Helvetica Neue', Arial,
      sans-serif;
}

.wordCount {
   color: red;
   font-weight: 200;
   background: var(--myWhite);
   padding: 0 0.5rem 0 0.5rem;

   /* position: absolute; */
   margin: 0.2rem;
   /* left: 1rem;
   top: 0; */
   opacity: 0.9;
   border-radius: 1rem;
   /* font-size: 1.3rem; */
   font-family: 'VT323', 'Helvetica Neue', Arial, sans-serif;
   border: 2px solid orange;
   animation: flashBorder 0.5s infinite;
}

.wordCountOrig {
   color: red;

   background: var(--myWhite);
   padding: 0 0.5rem 0 0.5rem;

   /* position: absolute; */
   margin: 0.2rem;
   /* left: 1rem;
   top: 0; */
   opacity: 0.9;
   border-radius: 1rem;
   /* font-size: 1.3rem; */
   font-family: 'VT323', 'Helvetica Neue', Arial, sans-serif;
   border: 2px solid orange;
   font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
   font-weight: 600;
   font-size: 1rem;
   /* animation: flashBorder 0.5s infinite; */
}

.filteringTypeWriter {
   color: red;
   background-color: var(--myWhite);
   display: inline-flex;
   padding: 2px 5px;
   border-radius: 10px;
   font-size: 0.4rem;
}

/* .myInput {
   appearance: none;
   border-radius: 0;
} */

.doubleContainer {
   display: flex;
   flex-wrap: wrap;
   height: 100vh;
   width: 100%;
   justify-content: space-between;
   /* border: 1px dashed lightgray; */
   align-content: flex-start;
   padding: 0rem;
   /* margin-bottom: 1rem; */
}

.configBox {
   width: 280px;
   padding: 0.5rem 0.3rem 0.5rem 0.9rem;
   border: 1px var(--myBrown) groove;
   border-radius: 10px;
   margin-bottom: 0.4rem;
}

.flashingBorder {
   border: 2px ridge yellow;
   /* padding: 20px; */
   animation: flashBorder 0.5s infinite;
}

@keyframes flashBorder {
   0%,
   100% {
      border-color: red;
   }
   50% {
      border-color: yellow;
   }
}

.flashingText {
   animation: flash-text 0.6s infinite;
   cursor: no-drop;
}

@keyframes flash-text {
   0%,
   100% {
      color: var(--myYellow);
   }
   50% {
      color: var(--myWhite);
   }
}
