.welcomeContainer {
   display: flex;
   flex-direction: column;
   width: 100%;
   justify-content: center;
   /* background-color: var(--myBrown);
   color: var(--myYellow); */
   /* border: 5px dashed yellow; */
   min-height: 100%; /* This will make the container take up at least the full viewport height */
   /* overflow-y: scroll !important; */
}

.flexVerticalContainer {
   display: flex;
   flex-direction: column;
   align-items: center;
   flex: 1; /* This will make .flexVerticalContainer take up the remaining vertical space */
   justify-content: space-evenly; /* This will space the child elements evenly */
   /* overflow-y: scroll !important; */
}

.welcomeParagraph {
   background-color: var(--myBrown);
   background-color: var(--myYellow);
   color: var(--myBrown);
   padding: 10px;
   font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
   font-size: 1.2rem;
   font-weight: 600;
   width: 80%;
   /* overflow-y: scroll; */

   @media screen and (max-width: 840px) {
   }
   @media screen and (max-width: 740px) {
      width: 96%;
      font-size: 1rem;
   }
   @media screen and (max-width: 340px) {
      width: 99%;
      font-size: 0.8rem;
   }
   @media screen and (min-width: 1000px) {
      width: 900px;
   }
}
.welcomeTitle {
   letter-spacing: 0rem;
   /* overflow-y: auto; */
   color: var(--myBrown);
   font-size: 1.6rem;
   padding-left: 10px;
   padding-right: 10px;
   @media screen and (max-width: 840px) {
      font-size: 1rem;
   }
}

/* Other styles... */
